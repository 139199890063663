* {
    margin: 0;
    padding: 0;
}

html, body {
    overflow-x: hidden;
    background-color: lightgray;
}


@-webkit-keyframes bounceInBefore-44 {
    0% {
        opacity: 0;
        transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
    }
    50% {
        opacity: 0.9;
        transform: scale(1.1) translate(-50%, -50%) rotate(45deg);
    }
    80% {
        opacity: 1;
        transform: scale(0.89) translate(-50%, -50%) rotate(45deg);
    }
    100% {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%) rotate(45deg);
    }
}

@keyframes bounceInBefore-44 {
    0% {
        opacity: 0;
        transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
    }
    50% {
        opacity: 0.9;
        transform: scale(1.1) translate(-50%, -50%) rotate(45deg);
    }
    80% {
        opacity: 1;
        transform: scale(0.89) translate(-50%, -50%) rotate(45deg);
    }
    100% {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%) rotate(45deg);
    }
}
@-webkit-keyframes bounceInAfter-44 {
    0% {
        opacity: 0;
        transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
    }
    50% {
        opacity: 0.9;
        transform: scale(1.1) translate(-50%, -50%) rotate(-45deg);
    }
    80% {
        opacity: 1;
        transform: scale(0.89) translate(-50%, -50%) rotate(-45deg);
    }
    100% {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%) rotate(-45deg);
    }
}
@keyframes bounceInAfter-44 {
    0% {
        opacity: 0;
        transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
    }
    50% {
        opacity: 0.9;
        transform: scale(1.1) translate(-50%, -50%) rotate(-45deg);
    }
    80% {
        opacity: 1;
        transform: scale(0.89) translate(-50%, -50%) rotate(-45deg);
    }
    100% {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%) rotate(-45deg);
    }
}
@-webkit-keyframes bounceInBeforeDont-44 {
    0% {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%) rotate(45deg);
    }
    100% {
        opacity: 0;
        transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
    }
}
@keyframes bounceInBeforeDont-44 {
    0% {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%) rotate(45deg);
    }
    100% {
        opacity: 0;
        transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
    }
}
@-webkit-keyframes bounceInAfterDont-44 {
    0% {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%) rotate(-45deg);
    }
    100% {
        opacity: 0;
        transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
    }
}
@keyframes bounceInAfterDont-44 {
    0% {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%) rotate(-45deg);
    }
    100% {
        opacity: 0;
        transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
    }
}

button {
    border: none;
    outline: none;
    background-color: #6c5ce7;
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 700;
    color: darkslategrey;
    border-radius: 5px;
    transition: all ease 0.2s;
}


.nav {
    overflow: hidden;
    height: 80px;
    background-color: #333333;
    display: flex;
    justify-content: space-between;
}

.nav ul {
    list-style: none;
    display: flex;
    gap: 1rem;
}

.circularLogo{
    transition: all 2s;
    box-sizing: border-box;
    max-width: 100%;
    animation-name: spinConstant;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.monPageCoachHeader {
    color: lightgreen;
    display: flex;
}
.smallButton {
    padding-left: 50px;
    line-height: 0;
    height: 20px;
    width: 10px;
}
@keyframes spinConstant {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.monStats {
    padding-top: 10px;
}
.nav a {
    height: 100%;
    display: flex;
    align-items: center;
    padding: .35rem;
}

.loginButton {
    padding: 5px;
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    width: 35%;
}

nav li.active {
    background-color: white;
    padding: 10px;
    border-radius: 70%;
    box-shadow: 0 0 9px 4px white;
    animation: glow 1.5s linear infinite;
    /*width: 100%;*/
}

.nav li:hover {
    background-color: white;
    padding: 10px;

    border-radius: 70%;
    box-shadow: 0 0 9px 4px white;
    animation: glow 1.5s linear infinite;
    /*width: 100%;*/
}

.submissionForm {
    margin: auto;
    display: none;
}

textarea {
    border-color: black;
    border-width: 5px;
    background-color: lightgrey;
}

input[type=text] {
    border-color: black;
    border-width: 5px;
    background-color: lightgrey;
}

input[type=text]:focus {
    border-color: lightblue;
    outline: 2px solid lightskyblue; /* oranges! yey */
}

.mainTextSubmit {
    margin: auto;
    padding: 5px;
    width: 300px;
    height: 200px;

}

.navIcon {
    margin: -15px;
    height: 70px;

}

a {
    color: black
}

a:link {
    text-decoration: none
}

button {
    background-color: darkslategrey; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    width: 120px;
    font-size: 16px;
}

button:hover {
    background-color: #3e8e41
}

button:active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);

}

pre {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 10px;
    height: 30px;
    width: 100%;

}

@keyframes slide-right-left {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slide-right-left {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slide-left-right {
    from {
        transform: translateX(0%);
        opacity: 0;
    }
    to {
        transform: translateX(100%);
        opacity: 1;
    }
}

@keyframes slide-down-up {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes minimize {
    from {
        transform: scale(1);
        opacity: 0;
    }
    to {
        transform: scale(0);
        opacity: 1;
    }
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.minimizeElement {
    animation: minimize .3s linear;
}

.slideUp {
    animation: slide-down-up .4s linear;
}

.slideLeft {
    animation: slide-right-left .4s linear;
}

.posts {
    animation: slide-right-left .4s linear;
}

.logoBen {
    height: 100px;
    margin: 5px;
}



    /* This begins the part of POKEMONS. NO IMAGEBOARD OR PLANNER OR ANYTHING GOES HERE JUST MONS. POKEMON START OF CSS*/
    .monPage {
        background: #999999 url("../public/pokebal.png") repeat;
        min-height: 100vh;
    }

    .realCoachHolder {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); /* Play with min-value */    }


.coachCardBlue .badge {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 5px 10px;
    border-radius: 50%;
    background-color: lightgreen;
    color: black;
}
.coachCardGreen .badge {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 5px 10px;
    border-radius: 50%;
    background-color: lightblue;
    color: black;
}
    .coachCardBlue {
        position: relative;
        display: inline-block;

        min-width: 400px;
        margin: 3px;
        width: fit-content;
        background-color: lightblue;
        border-style: solid;
        animation: slide-right-left .6s linear;

    }

    .coachCardGreen {
        position: relative;
        display: inline-block;

        min-width: 400px;
        animation: slide-right-left .4s linear;
        margin: 3px;
        width: fit-content;
        background-color: lightgreen;
        border-style: solid;
}

    .coachCardMonList {
        padding-left: 10px;
    }
    .pokemon {
        animation: slide-down-up .4s linear;
        color: black;
        /*background-color: #ffde00;*/
        background-color: white;
        width: 110px;
        height: 375px;
        padding: 5px;
        float: left;
        border-style: solid;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

.pokemonOwner {
    animation: slide-right-left .4s linear;
    color: black;
    /*background-color: #ffde00;*/
    background-color: lightblue;
    width: 170px;
    height: 375px;
    padding: 5px;
    float: left;
    border-style: solid;
    display: flex;
    flex-direction: column;
    align-items: center;
}
    .pokemonImage {
        height: 96px;
        width: 96px;
    }

    .pokeTypeHeader {
        display: flex;    }

    .pageMatchupHolder {
        float: right;
        border-style: groove;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    .matchupText {
        cursor: pointer;
    }

    .coachPageHeroHeader {

        margin: 0;
        text-align: center;
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        background-color: lightblue;
        color: #333333;
    }

    .coachPageVillainHeader {
        animation: slide-right-left .6s linear;

        margin: 0;
        text-align: center;
        justify-content: space-between;
        padding-left: 10px;
        background-color: lightgreen;
        color: #333333;
        display: none;
    }

    .statHolderMonColumn {
        padding-top: 5px;
        display: flex;
        float: left;
        text-align: center;
    }

    .coachPageHeroName {

        padding-left: 50px;
    }

    .matchupTextHolder {
        color: blue;
    }
    .monIconHolders {
        float: left;
    }

    .monColumnHolder {
        width: fit-content;
        display: flex;
        flex-direction: column;
    }

    .monColumnHolderGlowing {

        min-width: 150px;
        max-height: fit-content;
        box-shadow: 10px 0 40px 20px blue;
        display: flex;
        flex-direction: column;
    }
    .monColumnHolderGlowingV {
        width: fit-content;
        max-height: fit-content;

        box-shadow: 10px 0 40px 20px red;
        display: flex;
        flex-direction: column;
    }

    .oppMoves {
        padding-top: 15px;
        display: flex;
        flex-direction: column;
    }

    .typeCol{
        padding-left: 30px;
        float: left;

        min-width: 70px;
    }

    .nameCol {
        float: left;
        min-width: 150px;
    }

    .accCol {
        float: left;
        min-width: 50px;

    }

    .bpCol {
        padding-right: 2px;
        font-weight: bold;
        float: left;
        min-width: 80px;
    }

    .descriptionCol {
        float: left;
        min-height: 38px;
        max-width: 320px;
        max-height: fit-content;
    }

    .moveList {
        border-collapse: collapse;
        border: 0.2em;
        padding: 2px;
        border-style: outset;
        display: inline-block;
        text-align: center;
        min-height: 45px;
        max-height: fit-content;
        min-width: fit-content;
    }

    .moveHolder {
        display: flex;

    }

    .monButton {
        padding: 0;
        margin: 2px;
        height: 30px;
        width: 65px;
        box-shadow: 0 0 0 -.1em;
    }

    .copyright {
        float: right;
        width: 200px;
        position: absolute;
        bottom: 0; /* set the bottom to 0*/    }

    /* Dropdown Button */
    .dropbtn {
        background-color: #333333;
        color: white;
        border-style: solid;
    }

    /* The container <div> - needed to position the dropdown content */
    .dropdown {
        position: relative;
    }

    /* Dropdown Content (Hidden by Default) */
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: darkslategrey; /* Green */
        min-width: fit-content;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        z-index: 1;
    }

    /* Links inside the dropdown */
    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    /* Change color of dropdown links on hover */
    .dropdown-content a:hover {background-color: #ddd;}

    /* Show the dropdown menu on hover */
    .dropdown:hover .dropdown-content {display: block;}

    /* Change the background color of the dropdown button when the dropdown content is shown */
    .dropdown:hover .dropbtn {background-color: #3e8e41;}

    .rulesContainer {
        color: white;
    }


    .smallColours {
        align-content: center;
        text-align: center;
        margin: auto;
        border-style: solid;
        border-width: 1px;
        border-radius: 10px;
        width: 4vw;
        height: 20px;
        font-weight: bold;
    }

.typeChartCell {
    background-color: lightgrey;
    display: inline-block;
    margin: auto;
    padding-top: 5px;
    text-align: center;
    border-style: solid;
    border-width: thin;
    width: 4vw;
    height: 25px;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 3.5s;
}
.typeChartCellWeak {
    background-color: indianred;
    display: inline-block;
    margin: auto;
    padding-top: 5px;
    text-align: center;
    border-style: solid;
    border-width: thin;
    width: 4vw;
    height: 25px;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;

}.typeChartCellStrong {
     background-color: lawngreen;

     display: inline-block;
     margin: auto;
     padding-top: 5px;
     text-align: center;
     border-style: solid;
     border-width: thin;
     width: 4vw;
     height: 25px;
     animation-name: fadeInOpacity;
     animation-iteration-count: 1;
     animation-timing-function: ease-in;
     animation-duration: 1s;

 }

    .typeColours {
        align-items: center;
        font-size: 13px;
        text-align: center;
        float: left;
        width: 48px;
        border-radius: 10px;
        -moz-border-radius: 10px;
        -webkit-border-radius: 10px;
        -khtml-border-radius: 10px;
        -o-border-radius: 10px;
        border-style: solid;

    }

    .typeColours2 {

        border-style: solid;
    }

    .heroMonHolder {

        flex-wrap: wrap;
        display: flex;
    }

.villainMonHolder {
    display: flex;
    flex-direction: row;
    float: left;
    flex-wrap: wrap;
    padding-left: 130px;
}

    .heroMoves {
        padding-top: 15px;
        display: flex;
        flex-direction: column;
    }

    .monAbility {
        font-size: 15px;
        display: flex;
        flex-direction: column;
        font-weight: bold;
        white-space: nowrap;
        text-align: center;

    }
.monHomePage {
    display: flex;
    flex-direction: column;
}

.monName:hover .tooltiptext{
    visibility: visible;
    transition-delay: 0.3s;
}
.monName:hover {
    cursor: pointer;
}

    .monAbility:hover .tooltiptext{
        visibility: visible;
        transition-delay: 0.3s;
    }

.tooltiptext {
    visibility: hidden;
    background-color: lightgreen;
    color: black ;
    text-align: center;
    border-radius: 60px;
    padding: 5px 0;
    padding-left: 30px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}
    .actives {

        align-content: flex-start;
        float: left;
        flex-direction: row;
        justify-content: space-between;
    }
    .heroActiveZone {
        padding: 10px;
        float: left;
        display: flex;
        flex-direction: column;
        width: fit-content;
    }
    .villainActiveZone {
        padding: 5px;
        float: left;
        display: flex;
        flex-direction: column;

        width: fit-content;
    }
    .vsTextHero {
        position: relative;
        top: 150px;
        float: left;
    }
    .vsTextVillain {
        position: relative;
        top: 150px;
        float: left;
    }

    .typeChartName {

        line-height: 15px;
        font-weight: bold;
        font-size: 1vw;
        float: left;
        width: 15vw;
    }


    .bigGuy {
        width: 500px;
        font-size: 500rem;
    }


    .typeChart {
        width: 100%;
        padding-top: 50px;
    }

    .decorationHolders {
        padding-left: 15vw;
        display: flex;

    }
    .typeChartHeaderOpp {
        border-color: #333333;
        border-style: solid;
        color: #333333;
        background-color: lightblue;
    }
    .typeChartHeader {
        border-color: #333333;
        border-style: solid;

        text-align: left;
        color: #333333;
        background-color: lightblue;
    }
    .divider {
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
    .typeChartOpp {
        display: none;
    }


    .typeChartHolder {
        width: 100%;
        float: left;
        display: flex;
        flex-direction: column;
    }
    .statLeft {
        float: left;
        font-size: 13.5px;
        display: flex;
    }
.statRight {
    float: left;
    font-size: 13.5px;
    display: flex;
}


.projectHolder {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); /* Play with min-value */
padding: 15px;
}

.project {
    margin: 5px;
    padding: 5px;
    height: 250px;
    background-image: linear-gradient(white, lightblue);    width: 350px;
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}

.project:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.projectImg {
    width: 120px;
    transition: transform 4s;
    transform-style: preserve-3d;}


.homePageBody {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(177deg, #ADD8E6 0%, #90EE90 74%);
}

.paragraphHolderPortfolio  {
    margin: 35px;
    width: 80%;
}

.homePageProjects {
    text-align: left;
    font-size: 35px;
    font-weight: 600;
    background-image: linear-gradient(to left, #553c9a, #b393d3);

    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 33.33333vw;
}

.hold2images {
    display: flex;
}

/* accordion */

.container {
    animation: fadeInOpacity 0.2s linear;

    padding: 10px;
    color: darkgreen;
  max-width: 650px;
  width: 100%;
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
}

.wrapper1 {
    min-width: 800px;
    width: fit-content;
    margin: auto;

    background-color: lightblue;

    border-bottom: 1px solid black;
  overflow: hidden;
}
.wrapper0 {
    min-width: 800px;
    width: fit-content;

    margin: auto;

    background-color: lightgreen;

    border-bottom: 1px solid black;
    overflow: hidden;
}

.wrapper0 .rule-container {
  width: 100%;
  text-align: left;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
}
.wrapper1 .rule-container {
    width: 100%;
    text-align: left;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
}
.rule-container.active {
  color: black;
  background-image: linear-gradient(90deg,transparent,rgba(0,0,0,0.04),transparent);
}

.wrapper0 .rule-container:hover {
  background-image: linear-gradient(90deg,transparent,rgba(0,0,0,0.04),transparent);
}
.wrapper1 .rule-container:hover {
    background-image: linear-gradient(90deg,transparent,rgba(0,0,0,0.04),transparent);
}
.wrapper1 .arrow {
  font-size: 2rem;
  transition: .5s ease-in-out;
}
.wrapper0 .arrow {
    font-size: 2rem;
    transition: .5s ease-in-out;
}
.arrow.active {
  rotate: 180deg;
  color: #1db954;
}

.wrapper0 .answer-container {
  padding: 0 1rem;
  transition: height .7s ease-in-out;
}
.wrapper1 .answer-container {
    padding: 0 1rem;
    transition: height .7s ease-in-out;
}
.wrapper0 .answer-content {
    user-select: none;
    white-space: pre;
  padding: 1rem 0;
  font-size: 20px;
}
.wrapper1 .answer-content {
    user-select: none;

    white-space: pre;
    padding: 1rem 0;
    font-size: 20px;
}

.accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
}

.active, .accordion:hover {
    background-color: #ccc;
}

.panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.monPageHeader {
    color: white;
}



.monPageButton {
    width: 444px;
}


.pokeDexContainer {
    width: 375px;
    background-color: #03e5b7;
    background-image: linear-gradient(177deg, #ADD8E6 10%, #90EE90 90%);
    display: flex;
    flex-direction: column;
    animation: slide-right-left 0.75s linear;

}

.pokeDexChromeHolder {
    display: flex;
    padding: 5px;
}



.monDexSearchButton {

    height: 40px;
    padding-top: 5px;
}

.monDexClearButton {
    height: 40px;
    padding-top: 5px;
}

.monDexClearButton:hover {
    background-color: firebrick;
}

.monDexDropdown {
    font-size: 15px;
    height: 50px;
}

/* this floating searchbar placeholder thing thing */

.floating {
    margin-bottom: 2rem;
    background-color: var(--field__background);
    transition: background-color 0.2s ease;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.floating:hover,
.floating:focus-within {
    background-color: var(--field__background--hover);
}

.floating__input {
    width: 85px;
    height: 10px;
    padding: 1.8rem 1rem 0.6rem;
    font-size: 1rem;
    border-bottom: 0.1rem solid var(--input__border);
    transition: border-color 0.2s ease;
    caret-color: var(--color__accent);
}

.floating:hover .floating__input {
    border-color: var(--input__border--hover);
}

.floating__input::placeholder {
    color: rgba(0, 0, 0, 0);
}

.floating__label {
    display: block;
    position: relative;
    max-height: 0;
    font-weight: 500;
    pointer-events: none;
}

.floating__label::before {
    color: var(--label__color);
    content: attr(data-content);
    display: inline-block;
    filter: blur(0);
    backface-visibility: hidden;
    transform-origin: left top;
    transition: transform 0.2s ease;
    left: 1rem;
    position: relative;
}

.floating__label::after {
    bottom: 1rem;
    content: "";
    height: 0.1rem;
    position: absolute;
    transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 180ms cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s ease;
    opacity: 0;
    left: 0;
    top: 100%;
    margin-top: -0.1rem;
    transform: scale3d(0, 1, 1);
    width: 100%;
    background-color: var(--color__accent);
}

.floating__input:focus + .floating__label::after {
    transform: scale3d(1, 1, 1);
    opacity: 1;
}

.floating__input:placeholder-shown + .floating__label::before {
    transform: translate3d(0, -2.2rem, 0) scale3d(1, 1, 1);
}

.floating__label::before,
.floating__input:focus + .floating__label::before {
    transform: translate3d(0, -3.12rem, 0) scale3d(0.82, 0.82, 1);
}

.floating__input:focus + .floating__label::before {
    color: var(--color__accent);
}
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: darkslategrey;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.toggleLabel {
    margin: 5px;
    font-size: 20px;
    color: darkslategrey;
}

input[type="radio"],input[type="checkbox"]{
    display:none;
    cursor: pointer;
}
label{
    cursor: pointer;
}

/* /////for three state switch/////////////////////// */

.switch3-container h5{
    margin-bottom: .7rem;
}
.switch3
{
    position: relative;
    height:50px;
    display:flex;
    justify-content:space-around;
}
.switch3 label{
    text-align: center;
    font-size: 25px;
    padding: 1rem;
    z-index:1;
}
.switch3::before{
    content: '';
    position: absolute;
    left:0;
    width: 25%;
    height: 100%;
    background: #14142a;
    border: 1px solid #fff;
    border-radius: 12px;
    opacity: .5;
    transition: .5s left ease;
}

.switch3:has(#switch3-radio1:checked)::before{
    left: 0%;
}
.switch3:has(#switch3-radio2:checked)::before{
    left: 20%;
}
.switch3:has(#switch3-radio3:checked)::before{
    left: 40%;
}
.switch3:has(#switch3-radio4:checked)::before{
    left: 60%;
}

.switch3:has(#switch3-radio5:checked)::before{
    left: 80%;
}

.expandButton {
    padding-left: 20px;
    margin-left: 50px;
    height: 50px;
    width: 30px;
    ;
}
#monMatchupTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
}

#monMatchupTable td, #monMatchupTable th {
    color: black;
    border: 1px solid #ddd;
    padding: 8px;
}

.johtoHeader{background-color: lightblue;}
.kantoHeader{background-color: lightgreen;}

#monMatchupTable tr:hover {background-color: #ddd;}

#monMatchupTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: darkslategrey;
    color: white;
}

.coachHistoryLoss {
    background-color: indianred !important;
}
.coachHistoryWin {
    background-color: lightgreen !important;
}

.coachHistoryNeutral {
    background-color: lightblue;
}